import React from 'react'
import '../stylesheets/projects.css'
import FadeInSection from './FadeInSection'

import {
  Container,
  createStyles,
  Text,
  Space,
  Group,
  Card,
} from '@mantine/core'
import { IconBrandGithub, IconExternalLink, IconFolder } from '@tabler/icons'

const useStyles = createStyles((theme) => ({
  cardContainer: {
    padding: '28px',
    color: 'white',
    backgroundColor: '#233554',
    fontFamily: 'NTR, sans-serif',
    position: 'relative',
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: '16px',
  },
  cardSectionContainer: {
    padding: '28px',
    margin: '0px',
  },

  cardHeader: {
    marginTop: '-20px',
    display: 'flex',
    padding: '1.25em 0 !important',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: 'none !important',
  },

  cardTitle: {
    fontFamily: 'NTR, sans-serif',
    color: '#ccd6f6',
    fontSize: '24px',
    fontWeight: 'bold',
  },

  cardDesc: {
    fontFamily: 'NTR, sans-serif',
    color: '#8892b0',
    fontSize: '18px',
    maxWidth: '350px',
    lineHeight: '20px',
  },

  cardTech: {
    fontFamily: 'NTR, sans-serif',
    color: '#8892b0',
    fontSize: '16px',
    position: 'absolute',
    bottom: '20px',
    maxWidth: '80%',
    marginTop: '20px',
    lineHeight: '20px',
  },

  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(300px, 1fr))',
    gridGap: '20px',
    padding: '0px',

    '@media (max-width: 1080px)': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    },
  },
}))

const ProjectCard = ({ projects }) => {
  const { classes } = useStyles()

  return (
    <Container fluid={true} className={classes.gridContainer}>
      {Object.keys(projects).map((key, i) => (
        <FadeInSection delay={`${i + 1}00ms`} key={key}>
          <Card shadow="sm" className={classes.cardContainer}>
            <Container className={classes.cardSectionContainer}>
              <Card.Section inheritPadding className={classes.cardHeader}>
                <IconFolder size={48} stroke={1.5} />
                <Group>
                  <a
                    href={projects[key]['git']}
                    target="_blank"
                    className="aTag"
                  >
                    <IconBrandGithub size={24} stroke={1.5} />
                  </a>
                  {projects[key]['redirect'] !== '' ? (
                    <a
                      href={projects[key]['redirect']}
                      target="_blank"
                      className="aTag"
                    >
                      <IconExternalLink size={24} stroke={1.5} />
                    </a>
                  ) : (
                    <div></div>
                  )}
                </Group>
              </Card.Section>

              <Card.Section>
                <Text className={classes.cardTitle}>{key}</Text>
              </Card.Section>
              <Card.Section className={classes.cardDesc}>
                {projects[key]['desc']}
                <Space h={40} />
              </Card.Section>
              <Card.Section className={classes.cardTech}>
                <Group>{projects[key]['technologies']}</Group>
              </Card.Section>
            </Container>
          </Card>
        </FadeInSection>
      ))}
    </Container>
  )
} //const ender

export default ProjectCard
