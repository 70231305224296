import React, { useState } from 'react'
import '../stylesheets/index.css'
import Preloader from '../components/Preloader'
import Hero from '../components/Hero'
import About from '../components/About'
import Experience from '../components/Experience'
import Projects from '../components/Projects'
import Layout from '../components/Layout'
import { Container, createStyles } from '@mantine/core'
import '@fontsource/ntr'

const useStyles = createStyles((theme) => ({
  root: {
    padding: '0px',
    margin: '0px auto',
    width: '100vw',
  },
}))

// markup
const IndexPage = (props) => {
  const { classes } = useStyles()
  const [isLoading, setisLoading] = useState(true)

  const finishLoading = () => setisLoading(!isLoading)

  return (
    <Container className={classes.root} fluid={true}>
      <title>Kevin Liu</title>
      {isLoading ? (
        <Preloader finishLoading={finishLoading} />
      ) : (
        <Layout>
          <Hero />
          <About />
          <Experience />
          <Projects />
        </Layout>
      )}
    </Container>
  )
}

export default IndexPage
