import React from 'react'
import '../stylesheets/experience.css'
import FadeInSection from './FadeInSection'

import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import Grid from '@mui/material/Grid'

import { Container, createStyles, Text, Title } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  container: {
    color: 'white',
    margin: '0px auto',
    padding: '0px 15% 0px 15%',
    fontFamily: 'NTR, sans-serif',
    minHeight: '60vh',
    [`@media (max-width: ${'600px'})`]: {
      padding: '0',
    },
  },

  stack: {
    maxWidth: '455px',
  },

  headerText: {
    fontSize: '42px',
    fontFamily: 'NTR, sans-serif',
  },

  font: {
    fontFamily: 'NTR, sans-serif',
  },
}))

const Experience = (props) => {
  const { classes } = useStyles()

  return (
    <Container fluid={true} className={classes.container} id="experience">
      <Text
        className={classes.headerText}
        weight={900}
        align="left"
        gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
        variant="gradient"
        id="experiences"
      >
        / experience
      </Text>
      <Grid container justify="center">
        <Timeline position="right" sx={{ padding: '5px 0px 0px 0px' }}>
        <TimelineItem>
            <TimelineOppositeContent
              style={{ display: 'none' }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
              <img
                src="/company-logos/rivian-logo.jpg"
                width="75px"
                alt="rivian-logo"
              />
              <br />
              <TimelineConnector />
              <br />
            </TimelineSeparator>
            <FadeInSection delay={`100ms`}>
              <TimelineContent>
                <Title order={3} className={classes.font}>
                  Software Engineer Intern @ Rivian
                </Title>

                <h4 style={{ margin: '0px' }} className={classes.font}>
                  May 2023 - Sept 2023 <br />
                  Palo Alto, California
                </h4>
                <ul className={classes.font}>
                  <li>
                    Contributed to significant revenue growth by building core components of Rivian's B2B platform while on
Rivian's Vehicle Applications team; increased Rivian's revenue generated per vehicle
                  </li>
                  <li>
                    Helped architecture and implement the B2B client onboarding process workflow, through a subscriptions API
written in NestJS & Typescript, which performs CRUD operations to a DocumentDB (MongoDB)
                  </li>
                  <li>
                    Wrote and optimized a vehicle telemetry API endpoint by refactoring and migrating the API to wrap another
internal service, improving response time latency from an average of 700ms-900ms down to 100ms-300ms
                  </li>
                  <li>
                    Expedited the completion of the B2B platform MVP by developing a database abstraction layer when
establishing the DocumentDB connection, to ensure future scalability and adaptability to different databases
                  </li>
                  <li>
                    Created database schema migration scripts to spin up and down a collection in different dev-environments
using migrate-mongo and AWS Secrets Manager, saving over $200 per week on average
                  </li>
                  <li>
                    Provisioned DocumentDB clusters in development, QA, and staging environments utilizing
Terraform/Terragrunt, ensuring consistent and scalable infrastructure deployment
                  </li>
                  <li>
                    Performed rigorous load testing using Grafana K6 to ensure client-facing production API endpoints consistently
surpassed client expectations for 99.9% up-time and 100ms-300ms response-times
                  </li>
                  <li>
                    Leveraged Jest test suite to execute unit tests,resulting in an overall code coverage of over 95%
                  </li>
                </ul>
              </TimelineContent>
            </FadeInSection>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              style={{ display: 'none' }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
              <img
                src="/company-logos/amazon-logo.jpg"
                width="75px"
                alt="amazon-logo"
              />
              <br />
              <TimelineConnector />
              <br />
            </TimelineSeparator>
            <FadeInSection delay={`100ms`}>
              <TimelineContent>
                <Title order={3} className={classes.font}>
                  Software Developer Engineer Intern @ Amazon
                </Title>

                <h4 style={{ margin: '0px' }} className={classes.font}>
                  May 2022 - Aug 2022 <br />
                  Vancouver, Canada
                </h4>
                <ul className={classes.font}>
                  <li>
                    Developed an entire end-to-end internal web application that directly impacts over 200 million Amazon Prime
users while on Amazon's Prime Eligibility and Policy Platform Authority team
                  </li>
                  <li>
                    Launched a full-stack, internal self-service web portal UI for product managers to add new regional zone
configurations to existing Amazon Prime delivery benefits programs, which has reduced the time-to-market for
regional benefits by over 50%
                  </li>
                  <li>
                    Increased developer bandwidth by eliminating the manual end-to-end configuration request process by
streamlining the review workflow with automation, saving over 5 engineering hours per week
                  </li>
                  <li>
                    Protected internal web logins by implementing client-sided authentication with AWS Cognito, JSON Web Tokens
& internal services to restrict fraudulent access
                  </li>
                  <li>
                    Exposed a RESTful APIthrough AWS API Gateway to allow the frontend to communicate to API endpoints that
run integration & replay tests through an internal testing service
                  </li>
                  <li>
                    Published an internal Amazon Wiki page on React & TypeScript code conventions, defining future standards
                  </li>
                </ul>
              </TimelineContent>
            </FadeInSection>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent
              style={{ display: 'none' }}
            ></TimelineOppositeContent>
            <TimelineSeparator>
              <img
                src="/company-logos/yvr-logo.jpg"
                width="75px"
                alt="yvr-logo"
              />
              <br />
              <TimelineConnector />
            </TimelineSeparator>
            <FadeInSection delay={`200ms`}>
              <TimelineContent>
                <Title order={3} className={classes.font}>
                  Accounts Payable Intern @ Vancouver Airport Authority
                </Title>
                <h4 style={{ margin: '0px' }} className={classes.font}>
                  Jan 2020 - May 2020
                  <br />
                  Richmond, Canada
                </h4>
                <ul className={classes.font}>
                  <li>
                    Processed, reconciled, and managed large amounts of
                    invoices, which required daily problem-solving, improving
                    overall efficiency &amp; adaptability to ambiguous cases
                  </li>
                  <li>
                    Consolidated an auditing report, with the use of Excel,
                    Word, and Oracle, to compile and analyze large data sets
                  </li>
                  <li>
                    Lead an electric funds transfer (EFT) verification process,
                    that required external and internal research as well as a
                    phone call with vendors/suppliers, cultivating strong
                    communication &amp; research abilities
                  </li>
                  <li>
                    Reviewed and approved corporate expenses for internal
                    reimbursements, that involved case-by-case problems
                  </li>
                  <li>
                    Received and responded to a high volume of emails daily,
                    most of which require investigative tasks in order to solve
                    a problem, leading to the timeliness of payments to vendors
                  </li>
                </ul>
              </TimelineContent>
            </FadeInSection>
          </TimelineItem>
        </Timeline>
      </Grid>
    </Container>
  )
}

export default Experience
