import React, { useEffect, useState } from 'react'
import {
  createStyles,
  keyframes,
  Header,
  Button,
  Group,
  Burger,
  Text,
  Container,
  Menu,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconExternalLink } from '@tabler/icons'

export const fadeIn = keyframes({
  from: { opacity: '0' },
  to: { opacity: '1' },
})

const useStyles = createStyles((theme) => ({
  header: {
    paddingTop: theme.spacing.lg,
    paddingRight: theme.spacing.md,
    backgroundColor: '#1A1A1D',
    border: 'none',
    minHeight: 76,
  },

  text: {
    color: 'white',
    fontFamily: 'Inconsolata, monospace',
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '0.5s',
  },

  inner: {
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
    fontFamily: 'Inconsolata, monospace',
  },

  link: {
    fontFamily: 'Inconsolata, monospace',
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color:
      theme.colorScheme === 'light'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '1.25s',

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'light'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  button: {
    fontFamily: 'Inconsolata, monospace',
    outlineColor: 'white',
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '1.75s',

    '&:hover': {
      backgroundColor: '#F6E8EA',
      color: 'black',
    },
  },

  aBlock: {
    display: 'inline-block',
  },
}))

interface NavBarProps {
  links: { link: string; label: string }[]
}

export default function NavBar({ links }: NavBarProps) {
  const [opened, { toggle }] = useDisclosure(false)
  const { classes } = useStyles()

  const [showBurger, setShowBurger] = useState(false)

  const items = links.map((link) => (
    <a key={link.label} href={link.link} className={classes.link}>
      {link.label}
    </a>
  ))

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        setShowBurger(false)
      } else if (window.innerWidth < 992) {
        setShowBurger(true)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Container fluid={true}>
      <Header height={50} className={classes.header}>
        <div className={classes.inner}>
          <Group>
            <a>
              <Text size="xl" className={classes.text}>
                Kevin Liu
              </Text>
            </a>
          </Group>

          <Group>
            <Group ml={50} spacing={5} className={classes.links}>
              {items}
              <Button
                className={classes.button}
                component="a"
                href="https://drive.google.com/file/d/1aQg9WeBwe5BAdCtD9jJqh5mnETD9FNEb/view?usp=sharing"
                target="_blank"
                variant="outline"
                rightIcon={<IconExternalLink size={14} />}
                color="indigo"
              >
                Resume
              </Button>
            </Group>
            {/* TODO: Implement mobile burger menu */}
            {showBurger === true ? (
              <Menu shadow="md" width={110}>
                <Menu.Target>
                  <Burger size="sm" color={'white'} opened={opened} />
                </Menu.Target>

                <Menu.Dropdown>
                  <a href={'#about'} className={classes.aBlock}>
                    <Menu.Item>About Me</Menu.Item>
                  </a>
                  <Menu.Divider />
                  <a href={'#experiences'} className={classes.aBlock}>
                    <Menu.Item>Experience</Menu.Item>
                  </a>
                  <Menu.Divider />
                  <a href={'#projects'} className={classes.aBlock}>
                    <Menu.Item>Projects</Menu.Item>
                  </a>
                  <Menu.Divider />
                  <a href={'#contact'} className={classes.aBlock}>
                    <Menu.Item>Contact</Menu.Item>
                  </a>
                </Menu.Dropdown>
              </Menu>
            ) : (
              <div></div>
            )}
          </Group>
        </div>
      </Header>
    </Container>
  )
}

// fontFamily: 'Inconsolata, monospace',
