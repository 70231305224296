import React from 'react'
import '../stylesheets/preloader.css'

const Preloader = (props) => {
  return (
    <div className="loader">
      <h1
        onAnimationEnd={props.finishLoading}
        className="text"
        data-text="{ K }"
      >
        {'{ K }'}
      </h1>
    </div>
  )
}

// onAnimationEnd={props.finishLoading}

export default Preloader
