const projectsData = {
  InternConnect: {
    title: 'InternConnect',
    desc: 'A tool that helps students connect with tech recruiters!',
    image: '/images/internconnect-banner.png',
    technologies: [
      'Python, ',
      'JavaScript, ',
      'React, ',
      'AWS, ',
      'HMTL & CSS, ',
      'DynamoDB',
    ],
    git: 'https://github.com/kv-liu/InternConnect',
    redirect: 'https://intern-connect.vercel.app/',
  },
  'Data Visualization Dashboard': {
    title: 'Data Visualization Dashboard',
    desc: 'A dashboard that aggreates & displays a lot of data',
    image: '/images/datadashboard-banner.gif',
    technologies: [
      'Java, ',
      'Spring, ',
      'JavaScript, ',
      'HMTL & CSS, ',
      'PostgreSQL',
    ],
    git: 'https://github.com/jshggagnier/group9-bgcdata',
    redirect: '',
  },
  'Spotify Image Recognition': {
    title: 'Spotify Image Recognition',
    desc: "Takes in an image and recommends an image (isn't that cool?)",
    image:
      'https://images.unsplash.com/photo-1586095087956-bc66fe634955?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=932&q=80',
    technologies: [
      'Python, ',
      'JavaScript, ',
      'React, ',
      'AWS, ',
      'HMTL & CSS',
    ],
    git: 'https://github.com/na-wu/nwhacks2021',
    redirect: '',
  },
  'This website!': {
    title: 'My personal website',
    desc: 'This website build & designed by me!',
    image: '/images/website-banner.png',
    technologies: [
      'React, ',
      'Gatsby, ',
      'Mantine UI, ',
      'JavaScript, ',
      'HTML & CSS',
    ],
    git: 'https://github.com/kv-liu/personal-website',
    redirect: 'https://www.kvliu.com/',
  },
  'Reddit TLDR Extention': {
    title: 'Reddit TLDR Extention',
    desc: 'A Chrome Extention that summarizes lengthly Reddit posts',
    image:
      'https://images.unsplash.com/photo-1616509091215-57bbece93654?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80',
    technologies: ['JavaScript, ', 'TailWindCSS '],
    git: 'https://github.com/kv-liu/nwhacks2022/tree/main/tldrr',
    redirect: '',
  },
  'Discord Reminder Bot': {
    title: 'Discord Reminder Bot',
    desc: 'Sets a reminder and pings the user at the set time',
    image:
      'https://mk0droplrg5q83m5xg0r.kinstacdn.com/wp-content/uploads/2020/09/discord-bots-1200x400.png',
    technologies: ['Python'],
    git: 'https://github.com/kv-liu/Rimi',
    redirect: '',
  },
}

export default projectsData
