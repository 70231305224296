import React from 'react'
import {
  Container,
  Text,
  createStyles,
  Space,
  Image,
  Group,
  Stack,
} from '@mantine/core'
import '../stylesheets/about.css'
import FadeInSection from './FadeInSection'

import Technologies from './Technologies'

const useStyles = createStyles((theme) => ({
  container: {
    color: 'white',
    margin: '0px auto',
    padding: '0px 15% 0px 15%',
    fontFamily: 'NTR, sans-serif',
    minHeight: '60vh',
    minWidth: '30vh',
    alignItems: 'start',
    [`@media (max-width: ${'600px'})`]: {
      padding: '0',
    },
  },
  techContainer: {
    maxWidth: '490px',
    alignItems: 'start',
    margin: '0px',
    paddingLeft: '20px',
  },

  stack: {
    minWidth: '250px',
    maxWidth: '455px',
    paddingLeft: '20px',
  },

  headerText: {
    fontSize: '42px',
    fontFamily: 'NTR, sans-serif',
  },

  image: {
    minWidth: '250px',
    width: '300px',
    paddingLeft: '10px',
  },
  groupContent: {
    alignItems: 'start',
  },
  introHello: {
    padding: '0px',
    margin: '0px',
  },
}))

const About = (props) => {
  const { classes } = useStyles()

  return (
    <Container fluid={true} className={classes.container} id="about">
      <Group>
        <Text
          className={classes.headerText}
          weight={900}
          align="left"
          gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
          variant="gradient"
          id="about"
        >
          / about me
        </Text>
      </Group>
      <Space h="lg" />
      <Group className={classes.groupContent}>
        <FadeInSection delay={`150ms`}>
          <Image
            className={classes.image}
            radius="md"
            src="/images/profile-pic.jpg"
            alt="about-img-placeholder"
          />
        </FadeInSection>

        <Stack
          align="flex-start"
          justify="flex-start"
          className={classes.stack}
        >
          <FadeInSection delay={`100ms`}>
            <h1 className={classes.introHello}>Hello!</h1>
            <Text>
              Nice to meet you I'm Kevin (he/him)!
              <br />
              <br />
              I'm an undergrad student studying Computer Science & Business at
              Simon Fraser University. I am an individual who aspires to work on
              software products that can enrich the lives of others through
              creative software solutions. I have experience working in
              full-stack development, but would love the chance to be exposed to
              other areas of the software industry.
            </Text>
            <Space h="xs" />
          </FadeInSection>
          <FadeInSection delay={`200ms`}>
            <Text>
              When I'm not coding or studying for my final exams, you can find
              me playing my favourite video games (Valorant, League of Legends,
              Maplestory, Apex Legends), binge-watching shows, eating out or
              learning about the newest technologies in the tech space.
              <br />
            </Text>
            <Space h="xs" />
          </FadeInSection>

          <Space w="lg" />
        </Stack>
        <Container className={classes.techContainer}>
          <FadeInSection delay={`300ms`}>
            <Text>
              Here are a few languages, tools &amp; technologies I've worked
              with:
            </Text>
            <Space h="md" />
            <Technologies />
          </FadeInSection>
        </Container>
      </Group>
    </Container>
  )
}

export default About

// python, java, js, html, css, sql, react, typescript, aws, dynamodb, postgresql, spring
