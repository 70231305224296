import React from 'react'
import '../stylesheets/projects.css'
import ProjectCard from './ProjectCard'
import projectsData from '../datasets/projectsData'

import { Container, createStyles, Text, Space, Group } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  container: {
    color: 'white',
    margin: '0px auto',
    fontFamily: 'NTR, sans-serif',
    padding: '0px 15% 0px 15%',
    minHeight: '60vh',
    [`@media (max-width: ${'600px'})`]: {
      padding: '0',
    },
  },

  cardsContainer: {
    margin: '0px auto',
  },

  stack: {
    maxWidth: '455px',
  },

  headerText: {
    fontSize: '42px',
    fontFamily: 'NTR, sans-serif',
  },
}))

const Projects = (props) => {
  const { classes } = useStyles()

  return (
    <Container fluid={true} className={classes.container} id="projects">
      <Text
        className={classes.headerText}
        weight={900}
        align="left"
        gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
        variant="gradient"
        id="projects"
      >
        / projects
      </Text>
      <Space h="lg" />
      <Group className={classes.cardsContainer}>
        <ProjectCard projects={projectsData} />
      </Group>
    </Container>
  )
}

export default Projects
