import React from 'react'
import { Container, Group } from '@mantine/core'

const Technologies = () => {
  return (
    <Container>
      <Group>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/javascript-colored.svg"
            width="36"
            height="36"
            alt="JavaScript"
          />
        </a>
        <a href="https://www.oracle.com/java/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/java-colored.svg"
            width="36"
            height="36"
            alt="Java"
          />
        </a>
        <a href="https://www.python.org/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/python-colored.svg"
            width="36"
            height="36"
            alt="Python"
          />
        </a>
        <a
          href="https://www.typescriptlang.org/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/typescript-colored.svg"
            width="36"
            height="36"
            alt="TypeScript"
          />
        </a>
        <a
          href="https://developer.mozilla.org/en-US/docs/Glossary/HTML5"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/html5-colored.svg"
            width="36"
            height="36"
            alt="HTML5"
          />
        </a>
        <a
          href="https://www.w3.org/TR/CSS/#css"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/css3-colored.svg"
            width="36"
            height="36"
            alt="CSS3"
          />
        </a>
        <a href="https://mui.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/materialui-colored.svg"
            width="36"
            height="36"
            alt="Material UI"
          />
        </a>
        <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/react-colored.svg"
            width="36"
            height="36"
            alt="React"
          />
        </a>
        <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/tailwindcss-colored.svg"
            width="36"
            height="36"
            alt="TailwindCSS"
          />
        </a>
        <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/gatsby-colored.svg"
            width="36"
            height="36"
            alt="Gatsby"
          />
        </a>
        <a href="https://nodejs.org/en/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/nodejs-colored.svg"
            width="36"
            height="36"
            alt="NodeJS"
          />
        </a>
        <a href="https://www.postgresql.org/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/postgresql-colored.svg"
            width="36"
            height="36"
            alt="PostgreSQL"
          />
        </a>
        <a href="https://www.mysql.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/mysql-colored.svg"
            width="36"
            height="36"
            alt="MySQL"
          />
        </a>
        <a href="https://www.heroku.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/heroku-colored.svg"
            width="36"
            height="36"
            alt="Heroku"
          />
        </a>
        <a
          href="https://flask.palletsprojects.com/en/2.0.x/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/flask-colored-dark.svg"
            width="36"
            height="36"
            alt="Flask"
          />
        </a>
        <a href="https://www.figma.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/danielcranney/readme-generator/main/public/icons/skills/figma-colored.svg"
            width="36"
            height="36"
            alt="Figma"
          />
        </a>
        <a href="https://git-scm.com/doc" target="_blank" rel="noreferrer">
          <img
            src="https://user-images.githubusercontent.com/25181517/117364277-fc4eb280-aebd-11eb-8769-a3583c6a2037.png"
            width="36"
            height="36"
            alt="Git"
          />
        </a>
      </Group>
    </Container>
  )
}

export default Technologies
