import React from 'react'
import '../stylesheets/intro.css'
import { Container, createStyles } from '@mantine/core'
import NavBar from './NavBar'
import Footer from './Footer'

const useStyles = createStyles((theme) => ({
  mainContainer: {
    color: 'white',
    height: '85vh',
    padding: '0px',
    margin: '0px auto',
    width: '100vw',
  },
  childrenContainer: {
    margin: '0px',
    // maxWidth: '100vh',
    minWidth: '50vw',
  },
}))

const Layout = ({ children }) => {
  const { classes } = useStyles()

  var links = [
    { link: '#about', label: 'About' },
    { link: '#experiences', label: 'Experience' },
    { link: '#projects', label: 'Projects' },
  ]

  return (
    <Container fluid={true} className={classes.mainContainer}>
      <NavBar links={links} />
      <Container fluid={true} className={classes.childrenContainer}>
        {children}
      </Container>
      <Footer />
    </Container>
  )
}

export default Layout
