import React from 'react'
import '../stylesheets/intro.css'
import {
  Container,
  Text,
  createStyles,
  keyframes,
  Space,
  Button,
} from '@mantine/core'

export const fadeIn = keyframes({
  from: { opacity: '0' },
  to: { opacity: '1' },
})

const useStyles = createStyles((theme) => ({
  mainContainer: {
    color: 'white',
    height: '100vh',
    padding: '0px',
  },

  textContainer: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '85vh',
    padding: '0px',
    maxWidth: '600px',
  },

  introText: {
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '0.5s',
  },

  nameText: {
    fontSize: 'clamp(40px, 8vw, 80px)',
    fontFamily:
      'Calibre,Inter,San Francisco,SF Pro Text,-apple-system,system-ui,sans-serif',
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '0.75s',
  },

  infoText: {
    fontSize: '20px',
    maxWidth: '520px',
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '1.25s',
  },

  positionText: {
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '1.25s',
  },

  companyText: {
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '1.25s',
  },

  button: {
    marginTop: '50px',
    opacity: '0',
    animation: `${fadeIn} 2s forwards`,
    animationDelay: '1.5s',
    '&:hover': {
      backgroundColor: '#F6E8EA',
      color: 'black',
    },
  },
}))

const Intro = (props) => {
  const { classes } = useStyles()

  return (
    <Container fluid={true} className={classes.mainContainer} id="hero">
      <Container className={classes.textContainer}>
        <Text size="md" weight={500} className={classes.introText}>
          {' '}
          Hi, my name is{' '}
        </Text>
        <Space h="md" />
        <Text
          className={classes.nameText}
          weight={900}
          align="left"
          gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
          variant="gradient"
        >
          {' '}
          Kevin Liu.
        </Text>
        <Text align="left" className={classes.infoText}>
          I am a software engineer, studying Business &amp; Computer
          Science @ Simon Fraser University '24.
        </Text>
        <Space h="md" />
        <Text className={classes.positionText}>
          Currently seeking 2024 New Grad & Internships in Fall 2023, Winter 2024
        </Text>
        <Text className={classes.positionText}>
          SWE Intern @Rivian | Prev SDE Intern @Amazon
        </Text>
        <a href="mailto:kevinliu8260@hotmail.com">
          <Button className={classes.button} variant="outline" color="indigo">
            Contact Me
          </Button>
        </a>
      </Container>
    </Container>
  )
}

export default Intro
