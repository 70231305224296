import React from 'react'
import '../stylesheets/footer.css'
import FadeInSection from './FadeInSection'

import { Group, ActionIcon } from '@mantine/core'
import { IconBrandGithub, IconBrandLinkedin, IconMail } from '@tabler/icons'

const Footer = (props) => {
  return (
    <FadeInSection delay={`500ms`}>
      <div className="footer-container" id="contact">
        <div className="text-container">
          <div className="email-me-block">
            <h1>Interested in learning more?</h1>
            <p>
              Please feel free to email me at{' '}
              <a
                className="email-tag"
                href="mailto:kevinliu8260@hotmail.com"
                rel="noreferrer"
              >
                kevinliu8260@hotmail.com
              </a>{' '}
              if you would like to chat.
            </p>
            <p>Thanks for visiting! (: </p>
          </div>
          <div className="socials-block">
            <h1>Let's connect:</h1>
            <Group>
              <a
                href="http://linkedin.com/in/kv-liu"
                target="_blank"
                rel="noreferrer"
              >
                <ActionIcon>
                  <IconBrandLinkedin size={50} stroke={1.5} />
                </ActionIcon>
              </a>

              <a
                href="https://github.com/kv-liu"
                target="_blank"
                rel="noreferrer"
              >
                <ActionIcon>
                  <IconBrandGithub size={50} stroke={1.5} />
                </ActionIcon>
              </a>

              <a href="mailto:kevinliu8260@hotmail.com">
                <ActionIcon>
                  <IconMail size={50} stroke={1.5} />
                </ActionIcon>
              </a>
            </Group>
          </div>
        </div>

        <div className="footer-end">&copy; 2023 Kevin Liu</div>
      </div>
    </FadeInSection>
  )
}

export default Footer
